<template>
  <div
    ref="carousel_container"
    :class="containerClass"
    @click.left.capture="click($event)"
    @wheel="!disableScroll && wheelDrag && handleWheelScroll($event)"
    @mousedown.left.prevent="!disableScroll && mouseDrag && mouseDown($event)"
    @mouseup.left.prevent="!disableScroll && mouseDrag && mouseUp($event)"
    @mousemove.left.prevent="!disableScroll && mouseDrag && mouseMove($event)"
    @mouseleave="!disableScroll && mouseDrag && mouseLeave($event)"
    @touchstart="!disableScroll && touchStart($event)"
    @touchmove="!disableScroll && touchMove($event)"
    @touchend="!disableScroll && touchEnd($event)"
    @keyup.right="!disableScroll && keyDrag"
    @keyup.left="!disableScroll && keyDrag"
    @keyup.down="!disableScroll && keyDrag"
    @keyup.up="!disableScroll && keyDrag">
    <div
      v-for="(item, index) in items"
      :key="`carousel_item_${item.id ? item.id : index}`"
      ref="carousel_item"
      :class="itemClass(index)"
      :style="itemStyle(index)">
      <slot
        :item="item"
        :index="index" />
    </div>
  </div>
</template>

<script>
  /**
  * @vue-prop {Array} [items=[]] - Items' data that will be used to fill the component template passed to the slot.
  * @vue-prop {Number} [visibleItems=1] - Number of items always visible on the component. Can be a floating number (eg. 1.25)
  * @vue-prop {Boolean} [fillVisibleSpace=false] - If true, when the visibleItems value is bigger than the number of items, the item width will be
  calculated to fill all the visible space. Eg. Suppose we have set visibleItems to 4, but sometimes we only receive less than 4 items to show from the API.
  If fillVisibleSpace is set to true, item width will be calculated on each case: 1 item => 100%, 2 item => 50%, 3 item => 33.3%, 4 item => 25%, >4 item => 25%.
  If fillVisibleSpace is set to false, item width will always be calculated based on the visible items: 1 item => 25%, 2 item => 25%, ...
  * @vue-prop {Number|null} [selectedItemIndex=0] - Prop used to set the selected index from external components. When this prop changes, the internal variable
  selectedIndex is updated due to a watcher on this prop.
  * @vue-prop {Number} [itemDragBreakpoint=0] - Percentage of item width scrolled to automatically move to next item. Values between 0 and 1. High values imply
  a wider drag to change the selected index.
  * @vue-prop {Number} [gestureDirectionBreakpoint=60] - Gesture angle to detect horizontal movement. Values between 0 and 90. A value of 0 will result in not
  being able to scroll horizontally. A value of 1 will block vertical scroll on parent because every gesture will be considered horizontal drag on this component
  * @vue-prop {String} [scrollMode] - Actually unused. Sets the type of scrolling: continuous | snap | stack
  * @vue-prop {Boolean} [wheelDrag=true] - Wheel scroll active
  * @vue-prop {Boolean} [mouseDrag=true] - Mouse scroll active
  * @vue-prop {Boolean} [touchDrag=true] - Touch scroll active

  * @vue-data {Number} selectedIndex - Internal state of the selected item index
  * @vue-data {Boolean} isMousePressed
  * @vue-data {Boolean} isDragging - Flag active when isMousePressed is active and a move event has been launched.
  * @vue-data {Number} draggingStartPointX - Mousedown / touchstart pageX event value.
  * @vue-data {Number} draggingStartPointY - Mousedown / touchstart pageY event value.
  * @vue-data {Number} draggingStartScrollOffset - Mousedown / touchstart container scrollLeft value.
  * @vue-data {Number} draggingDirection - One of the DIRECTION constant values. Once a gesture has started the direction set is keeped until the mouseup /
  touchend events are launched to avoid strange behaviours due to changing de direction during the drag action.
  * @vue-data {Number} clickPositionRange - Maximum distance in pixels between the mousedown / touchstart and mouseup / touchend events to be considered a
  click event. If the distance is bigger the click event will be blocked.
  * @vue-data {Number} itemWidth - Carousel item width in pixels.
  * @vue-data {Object} carousel - Reference to the scrollable DOM node.

  * @vue-computed {Number} itemCount - Shortcut to this.items.length
  * @vue-computed {String} itemCssWidth - Item width in % to be used in css styling of each item container.

  * @vue-event {Number} selectedIndex - Emit the current selected index in the component's internal state.
  */
  import UtilsAnimate from 'CommonUtils/animate';

  const SCROLL_MODES = Object.freeze({
    SNAP: 'snap',
    CONTINUOUS: 'continuous',
    STACK: 'stack',
  });

  const DIRECTION = Object.freeze({
    VERTICAL: 1,
    HORIZONTAL: 2,
  });

  const ORIENTATION = Object.freeze({
    PREVIOUS: 1,
    NEXT: 2,
  })

  export default {
    name: 'MultiCarousel',
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      visibleItems: {
        type: Number,
        default: 1,
      },
      scrollDirection: {
        type: Number,
        default: DIRECTION.HORIZONTAL,
        validatior: function (value) {
          return Object.keys(DIRECTION).includes(value);
        }
      },
      fillVisibleSpace: {
        type: Boolean,
        default: false,
      },
      selectedItemIndex: {
        type: [Number, null],
        default: () => 0,
      },
      itemDragBreakpoint: {
        type: Number,
        default: 0,
        validator: function (value) {
          return value >= 0 && value <= 1;
        },
      },
      gestureDirectionBreakpoint: {
        type: Number,
        default: 60,
        validator: function (value) {
          return value >= 0 && value <= 90;
        },
      },
      scrollMode: {
        type: String,
        default: SCROLL_MODES.SNAP,
        validator: function (value) {
          return Object.values(SCROLL_MODES).includes(value);
        },
      },
      wheelDrag: {
        type: Boolean,
        default: true,
      },
      mouseDrag: {
        type: Boolean,
        default: true,
      },
      touchDrag: {
        type: Boolean,
        default: true,
      },
      arrowDrag: {
        type: Boolean,
        default: false,
      },
      blockWheelDrag: {
        type: Boolean,
        default: false,
      },
      wheelDragInterval: {
        type: Number,
        default: 250,
        required: false,
      },
      disableScroll: {
        type: Boolean,
        default: false,
      },
      isCdp: {
        type: Boolean,
        default: false,
      },
      isDesktopDevice: {
        type: Boolean,
        default: false,
      },
      enableInternalScroll: {
        type: Boolean,
        default: true,
        required: false,
      },
      animateEnabled: {
        type: Boolean,
        default: true,
        required: false,
      },
      animateScrollDuration: {
        type: Number,
        default: 500,
        required: false,
      },
      animateScrollTiming: {
        type: Function,
        default: UtilsAnimate.linearTiming,
        required: false,
      },
      animateScrollDraw: {
        type: [Function, null],
        default: null,
        required: false,
      },
      showScrollbarThin: {
        type: Boolean,
        default: false,
        required: false,
      }
    },
    emits: ['selected-index', 'is-dragging', 'finished-animation'],
    data() {
      return {
        selectedIndex: undefined,
        lastSelectedIndex: undefined,
        isMousePressed: false,
        isDragging: false,
        draggingStartPointX: null,
        draggingStartPointY: null,
        draggingLastPointX: null,
        draggingLastPointY: null,
        draggingLastOrientationAccumulatedOffset: 0,
        draggingStartScrollOffset: null,
        draggingStartSelectedIndex: undefined,
        draggingMovingIndex: undefined,
        draggingDirection: null,
        draggingInitialOrientation: null,
        lastWheelEvent: 0,
        clickPositionRange: 30,
        itemWidth: 0,
        itemWidthPx: '0px',
        carousel: null,
        carouselScrollOffset: 0,
        scrollingSlidesPercentage: this.items.map(() => 100),
        slideObserver: null,
        finishedMount: false,
        isFirstUpdate: true,
        scrollItem: null,
      };
    },
    computed: {
      itemCount() {
        return this.items && this.items.length ? this.items.length : 1;
      },
      itemCssWidth() {
        const fillScroll = this.itemCount < this.visibleItems && this.fillVisibleSpace === true;
        const numItems = fillScroll ? this.itemCount : this.visibleItems;
        return Math.round(100 / numItems) + '%';
      },
      containerClass() {
        if (this.showScrollbarThin) {
          return {
            'horizontal-carousel__container-chabot': this.scrollDirection == DIRECTION.HORIZONTAL,
            'vertical-carousel__container': this.scrollDirection == DIRECTION.VERTICAL,
            'horizontal-carousel__touch--block': !this.touchDrag,
            'disable-scroll': this.disableScroll,
          }
        } 
        return {
          'horizontal-carousel__container': this.scrollDirection == DIRECTION.HORIZONTAL,
          'vertical-carousel__container': this.scrollDirection == DIRECTION.VERTICAL,
          'horizontal-carousel__touch--block': !this.touchDrag,
          'disable-scroll': this.disableScroll,
        }
      },
      scrollProp() {
        return this.scrollDirection === DIRECTION.VERTICAL ? 'scrollTop' : 'scrollLeft';
      }
    },
    watch: {
      selectedIndex(newIndex, oldIndex) {
        if (newIndex !== oldIndex) {
          this.lastSelectedIndex = oldIndex || 0;
        }
      },
      selectedItemIndex(newIndex) {
        if (newIndex !== this.selectedIndex) {
          this.moveToIndex(newIndex);
        }
      },
      items() {
        this.resizeHandler();
      },
      carouselScrollOffset(newOffset) {
        if (this.carousel && this.enableInternalScroll) {
          this.carousel[this.scrollProp] = newOffset;
        }
      },
      itemWidth(newWidth) {
        this.itemWidthPx = newWidth + 'px';
      },
    },
    mounted() {
      this.carousel = this.$refs.carousel_container;
      this.resizeHandler();
      window.addEventListener('resize', this.resizeHandler);
      this.scrollItem = document.getElementsByClassName("horizontal-carousel__container-chabot")[0];
      this.scrollItem?.addEventListener('scroll', this.horizontalScrollHandler);
      if(this.isCdp && !this.isDesktopDevice && this.scrollDirection === DIRECTION.HORIZONTAL) {                
        this.carousel.addEventListener('scroll', this.checkIndex);  
      }  
    },
    unmounted() {
      window.removeEventListener('resize', this.resizeHandler);
      this.scrollItem?.removeEventListener('scroll', this.horizontalScrollHandler);
    },
    updated() {
      if(this.isFirstUpdate) {
        this.isFirstUpdate = false;
        this.finishedMount = true;
        this.resizeHandler();
      }
      if (this.calcItemWidth(this.carousel) !== this.itemWidth) {
        this.resizeHandler();
      }
    },
    methods: {
      itemClass(index) {
        const draggingSlide = this.isDraggingSlide(index);
        return {
          'horizontal-carousel__item': this.scrollDirection == DIRECTION.HORIZONTAL,
          'vertical-carousel__item': this.scrollDirection == DIRECTION.VERTICAL,
          'scrolled': index <= this.selectedIndex && !draggingSlide,
          'unscrolled': this.finishedMount && index > this.selectedIndex && !draggingSlide,
          'dragging': draggingSlide,
        };
      },
      isDraggingSlide(index, orientation) {
        if (this.scrollingSlidesPercentage[index] < 100  && this.isDragging) {
          if (!orientation) return true;
          if (this.lastSelectedIndex < this.selectedIndex) {
            return orientation === ORIENTATION.NEXT;
          } else if (this.lastSelectedIndex > this.selectedIndex) {
            return orientation === ORIENTATION.PREVIOUS;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      itemStyle(index) {
        const percent = this.scrollingSlidesPercentage[index];
        const scrolledPixels = this.itemWidth - (this.itemWidth*(percent/100)) + 'px';
        return {
          width: this.itemCssWidth,
          '--item-index': index,
          '--item-height': this.itemWidthPx,
          '--partial-scroll': percent,
          '--partial-scroll-px': scrolledPixels
        }
      },
      setCarouselOffset(offset, animated = false) {
        const scrollTo = typeof offset === 'number' ? offset : this.selectedIndex * this.itemWidth;
        let ratio = ((scrollTo+this.itemWidth) % this.itemWidth)/this.itemWidth;
        if (!this.enableInternalScroll) {
          this.carouselScrollOffset = scrollTo;
          if (this.isDragging) {
            const partialOffset =  offset - this.draggingStartScrollOffset;
            if(this.hasChangedOrientation(partialOffset)){
              ratio = this.draggingToNext(partialOffset) ? 0 : 1;
            }
            this.scrollingSlidesPercentage = this.scrollingSlidesPercentage.map((slide, index) => {
              // Added ratio !== 0 check to fix a race condition that happens rarely when dragging fast and the events mouseup and
              // click triggers stopDragging simultaneously, making the previous slide invisible since it is positioned as unscrolled.
              // This is just a visual effect during the current slide animation and dragging again will show it as if nothing had happened.
              return index === this.draggingMovingIndex && ratio !== 0 ? ratio*100 : 100;
            })
            if (!this.scrollingSlidesPercentage.find(slide => slide < 100)) {
              this.emitChangedIndex();
            }
          } else {
            this.emitChangedIndex();
          }
          return;
        }

        if (animated && this.animateEnabled) {
          this.animateScroll(scrollTo);
        } else {
          this.scrollingSlidesPercentage[this.selectedIndex] = ratio*100;
          this.carouselScrollOffset = scrollTo;
        }
      },
      animateScroll(scrollTo) {
        const instance = this;
        const scrollFrom = this.carouselScrollOffset;
        const movement = scrollTo - scrollFrom;
        const singleItemScrollProgress = Math.abs(movement/this.itemWidth);
        const duration = Math.abs(movement) < this.itemWidth ?
          singleItemScrollProgress*this.animateScrollDuration :
          this.animateScrollDuration;

        const targetIndex = this.selectedIndex;

        UtilsAnimate.animate({
          duration: duration,
          timing: this.animateScrollTiming,
          draw: (progress) => {
            if (progress < 0) return;
            const ratio = progress > 1 ? 1 : progress;
            if (movement > 0) {
              instance.scrollingSlidesPercentage[targetIndex] = ratio*100;
            }
            instance.carouselScrollOffset = scrollFrom + (movement * progress);
          },
          onFinish() {
            instance.scrollingSlidesPercentage[targetIndex] = 100;
            instance.carouselScrollOffset = scrollTo;
            instance.emitChangedIndex(true);
          }
        })
      },
      blockEvent(event) {
        if (event.cancelable) event.preventDefault();
        event.stopPropagation();
      },
      resizeHandler() {
        this.itemWidth = this.calcItemWidth(this.carousel);
        this.selectedIndex = this.selectedItemIndex || 0;
        this.setCarouselOffset();
        this.moveToIndex(this.selectedIndex);
        this.carousel.removeEventListener('scroll', this.checkIndex); 
        if(this.isCdp && !this.isDesktopDevice && this.scrollDirection === DIRECTION.HORIZONTAL) {            
          this.carousel.addEventListener('scroll', this.checkIndex);  
        }
      },
      horizontalScrollHandler() {
        //Not tested for VERTICAL direction
        if(this.scrollDirection !== DIRECTION.HORIZONTAL) return;

        if(!this.scrollItem) return;

        const max_scroll = this.scrollItem.scrollWidth - this.scrollItem.clientWidth;
        const scrollPercentage = this.scrollItem.scrollLeft / max_scroll;

        const segmentSize = 1 / this.items.length;
        let index = Math.floor(scrollPercentage / segmentSize);

        if(index >= this.items.length) {
          index = this.items.length-1;
        }

        this.setCarouselOffset(this.scrollItem.scrollLeft);
        this.selectedIndex = index;
      },
      checkIndex() {
        const max_scroll = this.carousel.scrollWidth - this.carousel.clientWidth;
        const scrollPercentage = this.carousel.scrollLeft / max_scroll;

        const segmentSize = 1 / this.items.length;
        let index = Math.floor(scrollPercentage / segmentSize);

        if(index >= this.items.length) {
          index = this.items.length-1;
        }               
        this.setSelectedIndex(index, true);  
      },
      hasMovedPointer(event) {
        const currentPosition = this.getCurrentPosition(event);
        const draggingStartPoint = this.getDraggingStartPoint();
        return (
          draggingStartPoint &&
          (draggingStartPoint + this.clickPositionRange < currentPosition ||
            draggingStartPoint - this.clickPositionRange > currentPosition)
        );
      },
      calcItemWidth(scrollable) {
        const containerRect = scrollable.getBoundingClientRect();
        if (this.scrollDirection === DIRECTION.HORIZONTAL) {
          if (this.scrollMode === SCROLL_MODES.STACK) {
            return containerRect.width * this.visibleItems;
          } else {
            return Math.ceil(scrollable.scrollWidth / this.itemCount);
          }
        } else if (this.scrollDirection === DIRECTION.VERTICAL) {
          if (this.scrollMode === SCROLL_MODES.STACK) {
            return (containerRect.height * this.visibleItems);
          } else {
            return Math.ceil(scrollable.scrollHeight / this.itemCount)+1;
          }
        }
      },
      resetDragging() {
        this.isMousePressed = false;
        this.isDragging = false;
        this.draggingStartPointX = null;
        this.draggingStartPointY = null;
        this.draggingLastPointX = null;
        this.draggingLastPointY = null;
        this.draggingLastOrientationAccumulatedOffset = 0;
        this.draggingMovingIndex = undefined;
        this.draggingStartScrollOffset = null;
        this.draggingStartSelectedIndex = undefined;
        this.draggingDirection = null;
        this.draggingInitialOrientation = null;
        this.$emit('is-dragging', false);
      },
      getDraggingStartPoint() {
        return this.scrollDirection == DIRECTION.HORIZONTAL ?
          this.draggingStartPointX :
          this.draggingStartPointY;
      },
      getCurrentPosition(event) {
        let x, y;
        if (event.type === 'touchmove') {
          x = event.changedTouches[0].pageX;
          y = event.changedTouches[0].pageY;
        } else if (event.type === 'mousemove') {
          x = event.pageX;
          y = event.pageY;
        }
        return this.scrollDirection == DIRECTION.HORIZONTAL ? x : y;
      },
      mouseDown(event) {
        if(event.target.offsetHeight != event.target.clientHeight)  return
        this.draggingStartPointX = event.pageX;
        this.draggingStartPointY = event.pageY;
        this.draggingStartScrollOffset = this.carouselScrollOffset;
        this.draggingStartSelectedIndex = this.selectedIndex;
        this.lastSelectedIndex = this.selectedIndex;
        this.isMousePressed = true;
      },
      touchStart(event) {
        this.draggingStartPointX = event.touches[0].pageX;
        this.draggingStartPointY = event.touches[0].pageY;
        this.draggingStartScrollOffset = this.carouselScrollOffset;
        this.draggingStartSelectedIndex = this.selectedIndex;
        this.lastSelectedIndex = this.selectedIndex;
      },
      touchMove(event) {
        if(!this.touchDrag) {
          return;
        }
        const startPoint = this.getDraggingStartPoint();
        if (startPoint !== null) {
          this.isDragging = true;
          this.$emit('is-dragging', true);
          const x = event.touches[0].pageX;
          const y = event.touches[0].pageY;

          if (this.draggingDirection === null) {
            this.draggingDirection = this.isHorizontalScroll(x, y) ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL;
          }

          if (this.draggingDirection == this.scrollDirection) {
            const currentPosition = this.getCurrentPosition(event);
            const offset = startPoint - currentPosition;
            this.setDraggingState(event, x, y, offset);
            this.moveItem(offset);
            this.blockEvent(event);
          }
        }
      },
      touchEnd(event) {
        if (this.isDragging) {
          this.stopDragging();
          if (this.draggingDirection == this.scrollDirection) {
            this.blockEvent(event);
          }
        } else {
          if (this.hasMovedPointer(event)) {
            this.blockEvent(event);
          }
        }
      },
      click(event) {
        this.isMousePressed = false;
        if (this.isDragging) {
          this.blockEvent(event);
          this.stopDragging();
        }
      },
      mouseLeave(event) {
        this.isMousePressed = false;
        if (this.isDragging) {
          this.stopDragging();
          this.blockEvent(event);
        }
      },
      mouseUp(event) {
        this.isMousePressed = false;
        if (this.isDragging) {
          this.blockEvent(event);
          this.stopDragging();
        }
      },
      mouseMove(event) {
        if (this.isMousePressed) {
          const currentPosition = this.getCurrentPosition(event);
          const offset = this.getDraggingStartPoint() - currentPosition;
          if (Math.abs(offset) > 10 || this.isDragging) {
            this.isDragging = true;
            this.$emit('is-dragging', true);
            const x = event.pageX;
            const y = event.pageY;
            if (this.draggingDirection === null) {
              this.draggingDirection = this.isHorizontalScroll(x, y) ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL;
            }

            if (this.draggingDirection == this.scrollDirection) {
              this.setDraggingState(event, x, y, offset);
              this.moveItem(offset);
              this.blockEvent(event);
            }
          }
        }
      },
      setDraggingState(event, x, y, offset) {
        this.draggingLastOrientationAccumulatedOffset = this.calculateDraggingAccumulatedOffset(event);
        this.draggingLastPointX = x;
        this.draggingLastPointY = y;
        if(!this.draggingInitialOrientation) {
          this.draggingInitialOrientation = this.calculateDraggingOrientation(offset);
          if( this.draggingInitialOrientation === ORIENTATION.PREVIOUS) {
            this.draggingMovingIndex = this.draggingStartSelectedIndex;
          } else {
            this.draggingMovingIndex = Math.min(this.itemCount -1, this.draggingStartSelectedIndex + 1);
          }
        }
      },
      moveItem(offset) {
        if (this.itemCount <= this.visibleItems) return;
        const absoluteOffset = this.draggingStartScrollOffset + offset;
        this.setCarouselOffset(absoluteOffset);
        let newIndex = this.selectedIndex;

        newIndex = this.calculateItemIndexOnDragging(this.draggingLastOrientationAccumulatedOffset);
        if (this.enableInternalScroll) {
          if (this.hasReachedEndLimit()) {
            newIndex = this.itemCount - 1;
          }
          if (this.hasReachedStartLimit()) {
            newIndex = 0;
          }
        } else {
          if (newIndex >= this.itemCount) {
            newIndex = this.itemCount-1;
          }
          if (newIndex < 0) {
            newIndex = 0;
          }
        }
        this.setSelectedIndex(newIndex, false);
      },      
      calculateDraggingOrientation(offset) {
        return this.draggingToNext(offset) ? ORIENTATION.NEXT : ORIENTATION.PREVIOUS;
      },
      calculateDraggingAccumulatedOffset(event) {
        const currentPosition = this.getCurrentPosition(event);
        const lastPosition = this.getDraggingLastPosition();
        const offset = lastPosition - currentPosition;
        const hasSameOrientation = (offset >= 0 && this.draggingLastOrientationAccumulatedOffset >= 0) ||
          (offset <= 0 && this.draggingLastOrientationAccumulatedOffset <= 0);

        return hasSameOrientation ?
          this.draggingLastOrientationAccumulatedOffset + offset :
          offset;
      },
      getDraggingLastPosition() {
        return this.scrollDirection === DIRECTION.HORIZONTAL ? this.draggingLastPointX : this.draggingLastPointY;
      },
      hasChangedOrientation(offset) {
        const draggingCurrentOrientation = this.calculateDraggingOrientation(offset);
        return this.draggingInitialOrientation !== draggingCurrentOrientation;
      },
      calculateItemIndexOnDragging(offset) {
        const movement = this.carouselScrollOffset / this.itemWidth;
        let newIndex = this.selectedIndex;

        if (this.scrollMode === SCROLL_MODES.STACK && this.hasChangedOrientation(offset)) {
          return this.draggingStartSelectedIndex;
        }

        if (this.draggingToNext(offset)) {
          // Si el item se ha desplazado a la derecha por encima del punto de corte, se mueve al item siguiente
          if (movement % 1 > this.itemDragBreakpoint) {
            newIndex = Math.ceil(movement);
          }
        } else {
          // Si el item se ha desplazado a la izquierda por encima del punto de corte, se mueve al item anterior
          if (movement % 1 < 1 - this.itemDragBreakpoint) {
            newIndex = Math.floor(movement);
          }
        }

        return newIndex;
      },
      calculateAngle(x, y) {
        let angle = Math.atan2(y, x); // range (-PI, PI]
        angle *= 180 / Math.PI; // rads to degs, range (-180, 180]
        //if (angle < 0) angle = 360 + angle; // range [0, 360)
        return angle;
      },
      calculateDragAngle(x, y) {
        const dy = y - this.draggingStartPointY;
        const dx = x - this.draggingStartPointX;
        return this.calculateAngle(dx, dy);
      },
      calculateWheelAngle(event) {
        return this.calculateAngle(event.deltaX, event.deltaY);
      },
      isVerticalScroll(x, y) {
        const angle = Math.abs(this.calculateDragAngle(x, y));
        return angle > this.gestureDirectionBreakpoint && angle < 180 - this.gestureDirectionBreakpoint;
      },
      isHorizontalScroll(x, y) {
        const angle = Math.abs(this.calculateDragAngle(x, y));
        return angle < this.gestureDirectionBreakpoint || angle > 180 - this.gestureDirectionBreakpoint;
      },
      isHorizontalWheelScroll(event) {
        const angle = Math.abs(this.calculateWheelAngle(event));
        return angle < this.gestureDirectionBreakpoint || angle > 180 - this.gestureDirectionBreakpoint;
      },
      moveToIndex(index) {
        this.setSelectedIndex(index, false);
        this.setCarouselOffset(this.itemWidth * index, true);
      },
      stopDragging() {
        this.setCarouselOffset(this.selectedIndex * this.itemWidth, true);
        //this.carousel.removeAttribute('style');
        //this.$nextTick(() => this.resetDragging());
        setTimeout(() => this.resetDragging(), 100);
      },
      handleWheelScroll(event) {
        const carouselDirection = this.isHorizontalWheelScroll(event) ? DIRECTION.HORIZONTAL : DIRECTION.VERTICAL;
        if(this.isCdp && !this.isDesktopDevice && carouselDirection === DIRECTION.HORIZONTAL) {
          return;
        }
        if (carouselDirection !== this.scrollDirection) {
          return;
        }

        const now = Date.now();
        const isSameWheelEvent = now - this.lastWheelEvent < this.wheelDragInterval;
        this.lastWheelEvent = now;
        if (this.isDragging || this.blockWheelDrag || isSameWheelEvent) {
          this.blockEvent(event);
          return;
        }
        this.blockEvent(event);

        const newIndex = this.getNewIndex(event);
        this.moveToIndex(newIndex);
      },
      getNewIndex(event) {
        let newIndex = this.selectedIndex;
        if (this.scrollToEnd(event)) {
          newIndex = this.selectedIndex < this.itemCount - 1 ? this.selectedIndex + 1 : this.itemCount - 1;
        } else if (this.scrollToStart(event)) {
          newIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : 0;
        }
        return newIndex;
      },
      hasReachedEndLimit() {
        if (this.scrollDirection === DIRECTION.HORIZONTAL) {
          return this.carouselScrollOffset >= this.carousel.scrollWidth - this.carousel.clientWidth;
        } else if (this.scrollDirection === DIRECTION.VERTICAL) {
          return this.carouselScrollOffset >= this.carousel.scrollHeight - this.carousel.clientHeight;
        }
      },
      hasReachedStartLimit() {
        return this.carouselScrollOffset <= 0;
      },
      hasReachedCarouselLimit(event) {
        return (
          (this.scrollToStart(event) && this.selectedIndex == 0) ||
          (this.scrollToEnd(event) && this.selectedIndex == this.itemCount - 1)
        );
      },
      scrollToEnd(event) {
        return this.scrollDirection === DIRECTION.VERTICAL ? event.deltaY > 0 : event.deltaX < 0;
      },
      scrollToStart(event) {
        return this.scrollDirection === DIRECTION.VERTICAL ? event.deltaY < 0 : event.deltaX > 0;
      },
      draggingToNext(offset) {
        return offset > 0;
      },
      setSelectedIndex(index, emit = true) {
        if (index !== this.selectedIndex) {
          this.selectedIndex = index;
          if(emit) {
            this.emitChangedIndex();
          }
        }
      },
      emitChangedIndex(finish_animate_scroll) {
        if(this.scrollMode === SCROLL_MODES.STACK) {
          const index = this.selectedIndex;
          this.$emit('selected-index', index)
          setTimeout(() => {
            this.$emit('finished-animation', index)
          }, this.animateScrollDuration);
        } else {
          this.$emit('selected-index', this.selectedIndex);
          if(finish_animate_scroll) this.$emit('finished-animation', this.selectedIndex);
          return;
        }
      },
      // setupSlideObserver() {
      //   this.slideObserver = new IntersectionObserver((entries) => {
      //     return entries.forEach(entry => {
      //       const { isIntersecting, intersectionRatio, target } = entry;
      //       if (this.scrollDirection == DIRECTION.VERTICAL) console.log(entry);
      //     });
      //   }, {
      //     root: this.carousel,
      //     rootMargin: '0px 0px 0px 0px',
      //     //threshold: Array.from({length: 100}, (_,i) => i*0.01), // [0, 0.01, 0.02, ...0.99]
      //     threshold: [0.01, 0.99],
      //     //threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      //     // delay: 500
      //   });

      //   this.$refs.carousel_item.forEach(element => {
      //     this.slideObserver.observe(element);
      //   });
      // }
    },
  };
</script>
