function linearTiming(timeFraction) {
  return timeFraction;
}

function parabolicTiming(timeFraction, power = 2) {
  return Math.pow(timeFraction, power);
}

function logarithmicTiming(timeFraction) {
  return (Math.log((timeFraction*100)+1)/Math.log(10))/2;
}

function animate({timing = linearTiming, draw, duration = 1000, onFinish = () => {}}) {

  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    let progress = timing(timeFraction)

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    } else {
      onFinish(progress)
    }

  });
}



module.exports = {
  animate,
  linearTiming,
  parabolicTiming,
  logarithmicTiming,
}